import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import { StaticQuery, graphql, Link } from "gatsby"
import {Helmet} from "react-helmet";
import { revealOutIn } from "../helpers/transitionHelper"
// import CountUp from "react-countup"

import UonLogo from '../images/uon_logo.svg'

import '../styles/style.scss'

class Layout extends React.Component {
  constructor() {
    super()
    this.state = {
      timing: 0.5,
      menuIsOpen: false,
      darkMode: false
    }
  }
  
  componentDidMount() {
    var that = this;

    setTimeout(function() {
      that.setState({
        timing: 0
      })
    }, 5000);
  }

  toggleMenu() {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen
    })
  }

  toggleDarkMode() {
    this.setState({
      darkMode: !this.state.darkMode
    })

    document.querySelector('html').classList.toggle('dark-mode')
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        childAnimationDelay: this.state.timing,
      }),
    );

    return (
      <StaticQuery query={query} render={ data => (

        <div className="site-layout">
          <Helmet 
            script={[{ 
              type: 'text/javascript', 
              innerHTML: 'window.paceOptions = {ajax: false}' 
            }]} 
          />
          <Helmet>
            <title>{data.sanitySiteSettings.title}</title>
            <link rel="stylesheet" href="https://use.typekit.net/esa6wxx.css"/>
            <script src="https://cdn.jsdelivr.net/npm/pace-js@latest/pace.min.js"/>
          </Helmet>

          <motion.header
            initial="initial"
            animate="enter"
            exit="exit"
            variants={{
              enter: { transition: { 
                staggerChildren: .05,
                delayChildren: 0
              }}
            }}
          >
            <h1 className={`${(this.props.path === '/contact/') ? 'big-logo' : ''}`}><motion.div variants={revealOutIn}><Link to="/"><span>{data.sanitySiteSettings.title}</span></Link></motion.div></h1>
            
            <div className="mainNav">
              {data.sanityMenus.items.map((item) => (item._type === 'internalLink') ? (
                <motion.span variants={revealOutIn} key={item._key}>
                  <Link activeClassName="active" to={`${item.link.slug.current}`}>{item.title}</Link>
                </motion.span>
              ) : <motion.span variants={revealOutIn} key={item._key}>
                    <a href={item.href} target="_blank" rel="noopener noreferrer">{item.title}</a>
                  </motion.span>
              )}

                <motion.span variants={revealOutIn} key="contact-link">
                  <Link activeClassName="active" to='/contact'>Contact</Link>
                </motion.span>

                <button className="menu-button" onClick={this.toggleMenu.bind(this)}>{this.state.menuIsOpen ? "close" : "menu"}</button>
            </div>
            
            <motion.div
              initial="initial"
              animate={this.state.menuIsOpen ? "enter" : "initial"}
              exit="exit"
              variants={{
                enter: { y: '0%', transition: {duration: .6, ease: [0.77, 0, 0.18, 1], staggerChildren: .1, delayChildren: 0.3}},
                initial: { y: '-100%', transition: {duration: .6, ease: [0.77, 0, 0.18, 1], delay: .4, staggerChildren: .02, staggerDirection: -1}}
              }}
              className="menu-wrap"
            >
              
              <motion.div className="menu-links">
                {data.sanityMenus.items.map((item) => (item._type === 'internalLink') ? (
                  <motion.span variants={revealOutIn} key={item._key}>
                    <Link onClick={this.toggleMenu.bind(this)} activeClassName="active" to={`${item.link.slug.current}`}>{item.title}</Link>
                  </motion.span>
                ) : <motion.span variants={revealOutIn} key={item._key}>
                      <a href={item.href} target="_blank" rel="noopener noreferrer">{item.title}</a>
                  </motion.span>
                )}
              
                <motion.span variants={revealOutIn} key="contact-link">
                  <Link onClick={this.toggleMenu.bind(this)} activeClassName="active" to='/contact'>Contact</Link>
                </motion.span>
              </motion.div>

            </motion.div>
          </motion.header>
          
          <AnimatePresence exitBeforeEnter>
            {childrenWithProps}
          </AnimatePresence>
          
          <motion.footer
            initial="initial"
            animate="enter"
            exit="exit"
            // variants={{
            //   enter: { transition: { 
            //     staggerChildren: .05,
            //     delayChildren: 0
            //   }}
            // }}
            variants={revealOutIn}
          >
            <motion.span className="uon-logo" >
              <a href="https://www.newcastle.edu.au/" target="_blank" rel="noreferrer">
                <img src={UonLogo} alt="University of Newcastle"/>
              </a>
            </motion.span>

            <motion.span className="footer-contact" >
              {/* <Link to='/contact'>contact</Link> */}
              {/* <button onClick={this.toggleDarkMode.bind(this)}>Dark Mode</button> */}
            </motion.span>

            <motion.span className="footer-copyright">&copy; {(new Date().getFullYear())}</motion.span>
            
          </motion.footer>
          
        </div>
      )}/>
    )
  }
}

export default Layout

const query = graphql`
  query MainMenuQuery {
    sanityMenus(slug: {current: {eq: "main"}}) {
        items {
          ... on SanityInternalLink {
            _key
            _type
            title
            link {
              ... on SanityPage {
                id
                title
                slug {
                  current
                }
              }
            }
          },
          ... on SanityExternalLink {
            _key
            _type
            title
            href: link
          }
        }
    }
    sanitySiteSettings {
      title
    }
  }
`