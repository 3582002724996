
export const fade = {
initial: { opacity: 0 },
  enter: { 
    opacity: 1,
    transition: { duration: 0.5, ease: [0.76, 0, 0.24, 1] }
  },
	exit: {
    opacity: 0,
		transition: { duration: 0.4, ease: [0.76, 0, 0.24, 1] }
	}
}

export const fadeSlow = {
initial: { opacity: 0 },
  enter: { 
    opacity: 1,
    transition: { duration: 0.3, ease: [0.33, 1, 0.68, 1],  }
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.3, ease: [0.33, 1, 0.68, 1] }
  }
}

export const revealInOut = {
	initial: { 
    y: "40px", 
    opacity: 0,
    transition: { duration: 0.6, ease: [0.33, 1, 0.68, 1] }
  },
	enter: { 
    y: "0px",
    opacity: 1,
    transition: { duration: 0.6, ease: [0.33, 1, 0.68, 1] }
  },
  exit: {
    y: "40px",  
    opacity: 0,
    transition: { duration: 0.4, ease: [0.76, 0, 0.24, 1] }
  }
}

export const revealInOutPercent = {
	initial: { y: "0%", rotate: '-5deg'},
	enter: { 
    y: "0%",
    rotate: '0deg',
    transition: { duration: 0.6, ease: [0.33, 1, 0.68, 1] }
  },
  exit: { 
    // y: "130%",
    transition: { duration: 0.4, ease: [0.76, 0, 0.24, 1] }
  }
}

export const revealInOutDelayed = {
	initial: { y: "130%" },
	enter: { 
    y: "0%",
    transition: { duration: 0.85, ease: [0.76, 0, 0.24, 1], delay: 0.2, }
  },
  exit: { 
    opacity: 0,
    transition: { duration: 0.4, ease: [0.76, 0, 0.24, 1] }
  }
}

export const revealInOutIn = {
	initial: { y: "130%" },
	enter: { 
    y: "0%",
    transition: { 
      repeat: 1,
      repeatType: "reverse",
      duration: 1,
      ease: [0.76, 0, 0.24, 1]
    }
  },
  exit: { 
    opacity: 0,
    transition: { duration: 0.4, ease: [0.76, 0, 0.24, 1] }
  }
}

export const revealInOutInSlow = {
	initial: { y: "130%" },
	enter: { 
    y: "0%",
    transition: { 
      duration: 1,
      ease: [0.76, 0, 0.24, 1],
    }
  },
  exit: { 
    opacity: 0,
    transition: { duration: 0.4, ease: [0.76, 0, 0.24, 1] }
  }
}


export const revealOutIn = {
	initial: { y: "40px", opacity: 0},
	enter: { 
    y: "0%",
    opacity: 1,
    transition: { duration: 0.6, ease: [0.76, 0, 0.24, 1] }
  },
  exit: { 
    y: "100%",
    opacity: 0,
    transition: { duration: 0.6, ease: [0.76, 0, 0.24, 1] }
  }
}

export const scaleReveal = {
  initial: { scale: 0.9, opacity: 0 },
	enter: { scale: 1, opacity: 1, transition: { duration: .6, ease: [0.77, 0, 0.18, 1] }},
  exit: { scale: 0, opacity: 0, transition: { duration: .6, ease: [0.77, 0, 0.18, 1] }}
}

export const lineVariants = {
	initial: { scaleX: 0 },
	enter: { scaleX: 1, transition: { duration: 0.4, ease: [0.65, 0, 0.35, 1] }},
  exit: {  scaleX: 0, transition: { duration: 0.4, ease: [0.65, 0, 0.35, 1] }}
}